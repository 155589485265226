import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
import { RemoveNullValues } from '../../utils/removeNullableValues';
const initialState = {
    status: 'idle',
    UpdateRentableStatus: 'idle',
    createRentableStatus: 'idle',
    listStatus: 'idle',
    checkConfigsStatus: 'idle',
    meta: { currentPage: 0, nextPage: false, prevPage: false, totalPages: 0, totalRecords: 0 },
    extrasMeta: { currentPage: 0, nextPage: false, prevPage: false, totalPages: 0, totalRecords: 0 },
    extrasStatus: 'idle',
    rentables: [],
    checkedConfigs: [],
    rentablesList: [],
    oneRentable: null,
    extras: [],
    attributeValues: [],
    attributes: [],
    vehicle: null,
    configs: [],
    acris: [],
    configValues: [],
    classes: [],
    branches: [],
    brands: [],
    rentableTypes: [],
    itemPaginationPage: 0,
    deleteManyCheckResponse: [],
};
export const getAllRentables = createAsyncThunk('vehicle/getAllRentables', async ({ page = 1, rowsPerPage = 10, orderBy = 'createdAt', order = 'desc', filterModel = '', pricingGroupId = '', branchId = '', classId = '', filterStatus = '', isExtra = 0, brandId = '', isPaging = true, rentableTypeId = '', }) => {
    let data;
    const queryParams = {
        search: {
            'branch.id': branchId,
            'rentableType.id': rentableTypeId,
            'model.brand.id': brandId,
            'class.id': classId,
            'rentableType.isExtra': isExtra,
            'pricingGroup.id': pricingGroupId,
            status: filterStatus === 'all' ? '' : filterStatus,
            registration_number: filterModel,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/rentables', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getAllRentablesList = createAsyncThunk('vehicle/getAllRentablesList', async ({ pricingGroupId = '', branchId = '', isExtra = 0, isPaging = false }) => {
    let data;
    try {
        const queryParams = {
            search: {
                'rentableType.isExtra': isExtra,
            },
            orderBy: 'id',
            sortedBy: 'desc',
            isPaging,
        };
        const url = createDynamicURL('/rentables', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        let filteredData;
        if (branchId !== '')
            filteredData = data?.data?.filter((el) => el.branchId?.toString() !== branchId?.toString());
        if (pricingGroupId !== '')
            filteredData = data?.data?.filter((el) => el.pricingGroupId?.toString() !== pricingGroupId?.toString());
        data.data = filteredData;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const getAllExtras = createAsyncThunk('vehicle/getAllExtras', async ({ page = 0, rowsPerPage = 10, pricingGroupId = '' }) => {
    let data;
    try {
        const queryParams = {
            search: {
                pricingGroupId,
            },
            page: Number(page) + 1,
            perPage: rowsPerPage,
            orderBy: 'id',
            sortedBy: 'desc',
        };
        const url = createDynamicURL('/rentables', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const addRentablesInBranch = createAsyncThunk('branches/addRentablesInBranch', async ({ Data, getParams }, thunkAPI) => {
    let data;
    try {
        const numberArray = Data?.ids?.map((str) => parseInt(str, 10));
        const response = await axios.put(`/branches/${Data.branchId}/rentables`, {
            ids: numberArray,
        });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getAllRentables({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const removeRentableFromBranch = createAsyncThunk('branches/removeRentableFromBranch', async ({ Data, getParams }, thunkAPI) => {
    let data;
    try {
        const numberArray = Data?.ids?.map((str) => parseInt(str, 10));
        const response = await axios.delete(`/branches/${Data.branchId}/rentables`, {
            data: { ids: numberArray },
        });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getAllRentables({ ...getParams }));
            thunkAPI.dispatch(getAllRentablesList({ branchId: getParams?.branchId }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const addRentable = createAsyncThunk('vehicle/addRentable', async (body, thunkAPI) => {
    let data;
    try {
        const response = await axios.post('/rentables', RemoveNullValues(body));
        data = await response.data;
        if (data.statusCode === 200) {
            thunkAPI.dispatch(getAllRentables({}));
            return response.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const CheckConfigs = createAsyncThunk('vehicle/CheckConfigs', async (data) => {
    try {
        const response = await axios.post('/configs/checkCodes', data);
        if (response.status === 200) {
            return response.data;
        }
        return response.data;
    }
    catch (error) {
        throw new Error(error.response.data.message);
    }
});
export const updateRentable = createAsyncThunk('vehicle/updateRentable', async (vehicleData) => {
    const { id, ...vehicle } = vehicleData;
    let data;
    try {
        const response = await axios.put(`/rentables/${Number(id)}`, {
            ...vehicle,
        });
        data = await response.data;
        if (data.statusCode === 200) {
            return response.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getRentableByID = createAsyncThunk('vehicle/getRentableByID', async (id) => {
    try {
        const response = await axios.get(`/rentables/${Number(id)}`);
        return response.data.data;
    }
    catch (error) {
        throw new Error(error.response.data.message);
    }
});
export const deleteOneRentable = createAsyncThunk('vehicle/deleteOneRentable', async (id, thunkAPI) => {
    try {
        const response = await axios.delete(`/rentables/${id}`);
        if (response.status === 200) {
            thunkAPI.dispatch(getAllRentables({ page: 0, rowsPerPage: 5, orderBy: 'createdAt', order: 'desc' }));
            return response.data;
        }
        return response.data;
    }
    catch (error) {
        return error;
    }
});
export const deleteManyRentables = createAsyncThunk('vehicle/deleteManyRentables', async (request) => {
    try {
        const response = await axios.delete(`/rentables/many`, { data: request.ids });
        return response.data;
    }
    catch (error) {
        return error;
    }
});
export const deleteCheckManyRentables = createAsyncThunk('vehicle/deleteCheckManyRentables', async (request, thunkAPI) => {
    try {
        const response = await axios.delete(`/rentables/many/checkReserved`, { data: request.ids });
        if (response.status === 200) {
            return response.data;
        }
        return response.data;
    }
    catch (error) {
        return error;
    }
});
export const getRentableAttributeValues = createAsyncThunk('vehicle/getRentableAttributeValues', async (id) => {
    let data;
    try {
        const response = await axios.get(`/rentableAttributeValues`);
        data = await response.data;
        if (response.status === 200) {
            return data.data?.filter((item) => item.rentableId === id) ?? [];
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getACRIS = createAsyncThunk('vehicle/getACRIS', async () => {
    let data;
    try {
        const response = await axios.get(`/acris`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getRentableAttributes = createAsyncThunk('vehicle/getRentableAttributes', async ({ isPaging }) => {
    let data;
    try {
        const response = await axios.get(`/rentableAttributes?isPaging=${isPaging}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data?.docs;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getConfigValues = createAsyncThunk('vehicle/getConfigValues', async ({ scope, branchId = '', categoryId = '', }) => {
    let data;
    try {
        const response = await axios.get(`/configValues?search=scope:${scope};branch.id:${branchId};class.id:${categoryId}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'rentable',
    initialState,
    reducers: {
        handleChangePaginationPage: (state, action) => {
            state.itemPaginationPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllRentables.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getAllRentables.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.rentables = action.payload?.data;
            state.meta = action.payload?.meta;
        })
            .addCase(getAllRentables.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getAllRentablesList.pending, (state) => {
            state.listStatus = 'loading';
        })
            .addCase(getAllRentablesList.fulfilled, (state, action) => {
            state.listStatus = 'succeeded';
            state.rentablesList = action.payload?.data;
        })
            .addCase(getAllRentablesList.rejected, (state, action) => {
            state.listStatus = 'failed';
        })
            .addCase(getAllExtras.pending, (state) => {
            state.extrasStatus = 'loading';
        })
            .addCase(getAllExtras.fulfilled, (state, action) => {
            state.extrasStatus = 'succeeded';
            state.extrasStatus = action.payload?.data;
            state.extrasMeta = action.payload?.meta;
        })
            .addCase(getAllExtras.rejected, (state, action) => {
            state.extrasStatus = 'failed';
        })
            .addCase(addRentable.pending, (state) => {
            state.createRentableStatus = 'loading';
        })
            .addCase(addRentable.fulfilled, (state, action) => {
            state.createRentableStatus = 'succeeded';
        })
            .addCase(addRentable.rejected, (state, action) => {
            state.createRentableStatus = 'failed';
        })
            .addCase(CheckConfigs.pending, (state) => {
            state.checkConfigsStatus = 'loading';
        })
            .addCase(CheckConfigs.fulfilled, (state, action) => {
            state.checkConfigsStatus = 'succeeded';
            const seen = new Set();
            state.checkedConfigs = action.payload.data.filter((el) => {
                const duplicate = seen.has(el.id);
                seen.add(el.id);
                return !duplicate;
            });
        })
            .addCase(CheckConfigs.rejected, (state, action) => {
            state.checkConfigsStatus = 'failed';
        })
            .addCase(updateRentable.pending, (state) => {
            state.UpdateRentableStatus = 'loading';
        })
            .addCase(updateRentable.fulfilled, (state, action) => {
            state.UpdateRentableStatus = 'succeeded';
        })
            .addCase(updateRentable.rejected, (state, action) => {
            state.UpdateRentableStatus = 'failed';
        })
            .addCase(deleteOneRentable.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteOneRentable.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteOneRentable.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteManyRentables.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteManyRentables.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteManyRentables.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getRentableAttributeValues.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getRentableAttributeValues.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.attributeValues = action.payload;
        })
            .addCase(getRentableAttributeValues.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getRentableAttributes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getRentableAttributes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.attributes = action.payload;
        })
            .addCase(getRentableAttributes.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getConfigValues.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getConfigValues.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.configValues = action.payload;
        })
            .addCase(getConfigValues.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getRentableByID.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getRentableByID.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.oneRentable = action.payload;
        })
            .addCase(getACRIS.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getACRIS.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getACRIS.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.acris = action.payload;
        })
            .addCase(getRentableByID.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(addRentablesInBranch.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(addRentablesInBranch.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(addRentablesInBranch.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(removeRentableFromBranch.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(removeRentableFromBranch.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(removeRentableFromBranch.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const { handleChangePaginationPage } = slice.actions;
export const reducer = slice.reducer;
export default slice;
